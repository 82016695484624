html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  scroll-behavior: smooth;
}

body { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  position: relative;

  min-height: 100vh;
}

* {
  font-family: 'Raleway', sans-serif;
}

h1 {
  font-size: 2em;
}

section {
  padding: 50px 0;
}

/* Header */

.Header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  z-index: 100;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  width: 100%;
  height: 65px;
  padding: 0 14%;

  background-color: white;

  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
}

.header-logo-wrapper {
  height: 100%;
  width: 24%;
}

.header-logo {
  height: 100%;
  padding: auto;

  cursor: pointer;
}

.header-phone-number {
  color: #00c800;
  font-size: 1.2em;

  text-decoration: none;
  text-align: center;
  
  width: 24%;
}

.header-menu-button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 24%;
  height: 50px;
  padding: 0;

  border: none;
  background: none;
}

.header-menu-button-wrapper > svg {
  width: 25px;
  height: 25px;

  cursor: pointer;

  fill: #00c800;
}

.nav-menu {
  position: absolute;
  right: calc(14% - 25px);
  top: 100%;

  display: flex;

  z-index: -1;
}

.nav-menu-list {
  position: relative;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 300px;

  padding: 0;
  margin: 0;
  list-style: none;
  
  transition: margin-top 1s, box-shadow 1s;

  background-color: rgb(255, 255, 255);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.nav-menu-hidden {
  opacity: 0;
  margin-top: -80%;

  animation: menu-slide-out 1s;
}

.nav-menu-list > a {
  color: inherit;
  text-decoration: none;
  width: 100%;
}

.nav-menu-list li {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  width: 100%;

  cursor: pointer;

  transition: background-color 0.5s, color 0.5s;
}

.nav-menu-list li:hover {
  background-color: rgb(243, 243, 243);

  transition: background-color 0.5s, color 0.5s;

  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .header-wrapper {
    padding: 0 5%;

    height: 60px;
  }

  .header-logo-wrapper {
    width: 30%;
  }

  .header-phone-number {
    width: 30%;
  }

  .header-menu-button-wrapper {
    width: 30%;
  }

  .nav-menu {
    right: 0%;
  }
  
  .nav-menu-list {
    width: 400px;

    font-size: 1em;
  }
  
  .nav-menu-list > li {
    padding: 15px 10px;
  }
}

@media screen and (max-width: 600px) {
  .header-wrapper {
    padding: 0 5%;

    height: 60px;
  }

  .header-logo-wrapper {
    display: flex;
    align-items: center;

    width: 25%;
  }

  .header-logo {
    height: 70%;
  }

  .header-phone-number {
    width: 40%;

    font-size: 1em;
  }

  .header-menu-button-wrapper {
    width: 25%;
  }

  .nav-menu {
    right: 0%;
    width: 100%;
  }
  
  .nav-menu-list {
    width: 100%;

    font-size: 1em;
  }
  
  .nav-menu-list > li {
    padding: 15px 10px;
  }

  .nav-menu-hidden {
    margin-top: -100%;
  }
}

/* End of Header component */

/* Footer component */

.Footer {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #efefef;

  width: 100%;
  height: 150px;
}

.footer-copyright {
  text-align: center;
}

/* End of Footer component  */

/* Home page */

.Home {
  width: 100%;
  overflow: hidden;
}

/* Welcome section */

.welcome-section {
  position: relative;

  box-sizing: border-box;
  margin: 0;
  width: 100%;
  max-width: 100vw;

  z-index: -1;
}

.welcome-section-text {
  position: absolute;

  left: 20%;
  top: 25%;
}

.welcome-section-text > h1 {
  background-color: white;
  padding: 30px;
  margin: 0;
  font-size: 3em;
  text-align: center;
  width: 400px;

  color:#00c800;

  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.3);
}

.welcome-section-text > h3 {
  background-color: white;
  padding: 25px;
  margin: 0;
  margin-left: 200px;
  width: 300px;

  font-weight: 400;
  text-align: right;

  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.3);
}

.landing-photo { 
  width: 100vw;
  height: 500px;

  object-fit: cover;
}

@media screen and (max-width: 1050px) {
  .welcome-section-text {
    top: 25%;
    left: 10%;
  }

  .welcome-section-text > h1 {
    padding: 25px;
    font-size: 2.5em;
    width: 350px;
  }

  .welcome-section-text > h3 {
    padding: 20px;
    margin-left: 150px;
    width: 250px;
    font-size: 1em;
  }

  .landing-photo { 
    height: 400px;
  }
}


@media screen and (max-width: 720px) {
  .welcome-section-text {
    top: 25%;
    left: 5%;
  }

  .welcome-section-text > h1 {
    box-sizing: border-box;

    padding: 5%;
    font-size: 2em;
    width: 80vw;

    box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.5);
  }

  .welcome-section-text > h3 {
    padding: 5%;
    margin-left: 25%;
    width: 80%;
    font-size: 0.9em;

    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
  }

  .landing-photo { 
    height: 300px;
  }
}

/* End of welcome section */

/* Our Courses section */

.our-courses {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.our-courses-list {
  box-sizing: border-box;
  margin: 50px 0;
  list-style: none;
  padding: 0;

  width: 60%;
}

.our-courses-list > li {
  box-sizing: border-box;
  padding: 20px;
  margin: 5px 0;
  border-left: 5px solid #00cc00;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

  font-size: larger;
  font-weight: 600;
}

@media screen and (max-width: 720px) {
  .our-courses-list {
    margin: 30px 0;
  
    width: 80%;
  }
}

/* End of Our Courses Section */ 

/* English Course Section */

.english-course {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.english-course-age {
  color: #00cc00;
  margin: 0;
  font-weight: 400;
  font-style: italic;
}

.english-course-advantages {
  list-style: none;
}

.english-course-advantages > li {
  margin: 10px 0;
  font-size: 1.3em;
}

.english-course-advantages > li::before {
  width: 1.3rem;
  height: 1.6rem;
  content: "";
  display: block;
  float: left;
  margin-left: -1.6rem;

  background: url(../images/icons/tick.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.english-course-important {
  font-size: 1em;
  color: red;
  font-weight: bold;
}

.english-course-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  text-align: center;

  padding: 40px 0 0;
}

.english-course-results > .Slider {
  padding: 50px 50px 0px;
  width: auto;
  height: auto;
}

.english-course-results .slider-wrapper {
  width: 600px !important;
}

.english-course-results .slide {
  width: 600px !important;
  height: 400px;
}

.english-course-results > h2 {
  margin: 0;
}

@media screen and (max-width: 720px) {
  .english-course-advantages {
    margin: 20px;

    padding: 0 2rem;
  }

  .english-course-advantages > li {
    margin: 20px 0;
    font-size: 1.1em;
  }
  
  .english-course-advantages > li::before {
    width: 1.2rem;
    height: 1.4rem;
    margin-left: -1.5rem;
  }
  
  .english-course-important {
    margin: 0 50px;
  }

  .english-course-results > .Slider {
    padding: 50px 50px 0px;
    width: auto;
    height: auto;
  }
  
  .english-course-results .slider-wrapper {
    width: 400px !important;
  }
  
  .english-course-results .slide {
    width: 400px !important;
    height: 300px;
  }
}


@media screen and (max-width: 550px) {
  .english-course-results > .Slider {
    padding: 30px 30px 0px;
    width: auto;
    height: auto;
  }
  
  .english-course-results .slider-wrapper {
    width: 300px !important;
  }
  
  .english-course-results .slide {
    width: 300px !important;
    height: 200px;
  }
}

@media screen and (max-width: 420px) {
  .english-course-results > .Slider {
    padding: 15px 15px 0px;
    width: auto;
    height: auto;
  }
  
  .english-course-results .slider-wrapper {
    width: 300px !important;
  }
  
  .english-course-results .slide {
    width: 300px !important;
    height: 200px;
  }
}

/* End of English Course section */

/* Our Teachers section */

.our-teachers {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
}


@media screen and (max-width: 720px) {
  .our-teachers .Slider {
    width: 350px;
  }

  .our-teachers .slider-wrapper {
    width: 300px !important;
  }

  .our-teachers .slide {
    width: 300px !important;
  }
}

@media screen and (max-width: 420px) {
  .our-teachers .Slider {
    width: 300px;
  }

  .our-teachers .slider-wrapper {
    width: 260px !important;
  }

  .our-teachers .slide {
    width: 260px !important;
  }
}

/* End of Our Teachers section */

/* Courses Costs section */

.courses-cost {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.courses-cost-cards {
  box-sizing: border-box;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  column-gap: 10px;
  row-gap: 10px;
  justify-content: center;

  padding: 20px;
}

/* End of Courses Costs section */ 

/* End of Home page */

/* Login page */

.Login {
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  margin-top: -100px;
  height: 100vh;
  width: 100vw;
}

.login-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.login-form > label {
  padding: 5px;
}

.login-form > input {
  outline: none;
  border-radius: 50px;
  border: 1px solid black;
  padding: 3px 10px;
}

.login-form > input[type="submit"] {
  width: 100%;
  margin: 10px 0;
  background-color: white;
  border: none;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

  cursor: pointer;
}

.login-form > input[type="submit"]:hover {
  background-color: rgb(240, 240, 240);
}

/* End of Login page */

/* Slider component */

.Slider {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 400px;

  margin: 25px 0;
  padding: 40px 5% 0;
  background-color: white;
  box-shadow: 1px 1px 5px rgba(66, 66, 66, 0.5);
}

.slider-wrapper {
  position: relative;

  width: 400px;
  overflow: hidden;
  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-photo-area {
  position: relative;
  align-self: flex-start;
  
  height: 80%;
  top: 0;

  transition: left 1s;

  display: flex;
}

.slide {
  width: 400px;
  
  display: flex;
  justify-content: flex-start;
}

.slider-photo {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.slider-video {
  border: none;
}

.slider-title {
  margin: 0;
  margin-top: 20px;
  line-height: normal;

  font-weight: 400;
}

.slider-title.empty {
  opacity: 0;
  visibility: hidden;
}

.slider-controls {
  bottom: 0;

  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: 20%;
  margin: 20px;
}

.slider-jump-to-buttons-area {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;
}

.slider-controls-button {
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: none;

  cursor: pointer;
}

.slider-controls-button > svg {
  height: 20px;
}

.slider-controls-button:hover > svg {
  fill: grey;
}

.slider-jump-to-button {
  box-sizing: border-box;
  width: 25px;
  height: 5px;

  padding: 0;
  margin: 0 3px;

  border: none;
  background: rgb(195, 195, 195);
  box-shadow: 1px 1px 3px rgb(192, 192, 192);

  cursor: pointer;
}

.slider-jump-to-button:hover {
  background-color: rgb(123, 123, 123)
}

.slider-jump-to-button-active {
  background-color: rgb(70, 70, 70) !important;
}

/* End of slider component */

/* Price Card component */

.PriceCard {
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  width: 100%;
  height: fit-content;

  overflow: hidden;

  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.price-card-title {
  box-sizing: border-box;
  font-size: 1.5em;
  font-weight: 400;
  width: 100%;
  text-align: center;
  padding: 15px;
  background-color: #f8f8f8;
  color: black;

  margin: 0;
}

.price-card-hr {
  width: 100%;
  margin: 0;
  border: none;
  border-top: 1px solid rgb(187, 187, 187);
}

.price-card-course-type {
  color: #00cc00;
  border: 2px solid #00cc00;
  padding: 2px 5px;
  margin: 10px 0 0;
  border-radius: 50px;
}

.price-card-price {
  color: grey;
}

.price-card-price > span {
  font-size: 2.5em;
  color: black
}

.price-card-description {
  font-size: 1em;
  margin: 0;
}

.price-card-minimal {
  box-sizing: border-box;
  text-align: center;
  padding: 3px;
  margin: 25px 0px;
  width: 60%;
  border-radius: 5px;
  background-color:#ddffdd;
  font-style: italic;
}

/* End of Price Card component */

/* ImagePicker component */

.ImagePicker {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 250px;

  border-radius: 25px;

  overflow: hidden;
}

.ImagePicker-wrapper {
  position: absolute;

  border-radius: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);

  cursor: pointer;
  
  z-index: 2;
}

.ImagePicker-wrapper:hover {
  background-color:rgba(0, 0, 0, 0.1);
}

.add-image-button {
  width: 50px;
  height: 50px;

  opacity: 0.5;
}

.add-image-button.image-uploaded {
  opacity: 0;
}

.ImagePicker-wrapper:hover .add-image-button.image-uploaded {
  opacity: 0.5;
}

.uploaded-image {
  position: absolute;

  max-width: 100%;
  max-height: 100%;
  object-fit: contain;

  z-index: 1;
}

.image-input {
  display: none;
}

/* End of ImagePicker component */

/* Animations */

@keyframes menu-slide-in {
  from { margin-top: -100%; }
  to { margin-top: 0%; }
}

@keyframes menu-slide-out {
  from { opacity: 1; }
  to { opacity: 1; }
}